





import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { AdminRoutes } from "@/shared/router/admin";
import FormBase from "@/shared/classes/form/form-base";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import { getNodeApiUrlByVersion } from "@/config";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import Form from "@/shared/components/form/Form.vue";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import Course from "@/shared/modules/courses/course.model";

@Component({
  components: { Form },
  methods: { __ }
})
export default class Overview extends Vue {
  form: FormBase | null = null;

  created() {
    this.form = new FormBase()
      .setUuid(this.$router.currentRoute.params.uuid)
      .setEndpoint(`${getNodeApiUrlByVersion()}/courses`)
      .setModel(Course)
      .setMethod(HttpMethod.PATCH)
      .setTranslatable(true)
      .setFiles(true)
      .setFields([
        new Field()
          .setKey('name')
          .setTranslatable(true)
          .setTitle(__("admin.views.courses.form.name"))
          .isRequired(),
        new Field()
          .setType(FieldTypes.file)
          .setKey('picture')
          .setTitle(__('admin.views.courses.form.picture'))
          .setMeta({ type: 'image', accept: 'image/*' }),
        new SearchableField()
          .setKey("courseCategoryUuid")
          .setTitle(__("admin.views.courses.form.category"))
          .loadItems({
            endpoint: `${getNodeApiUrlByVersion()}/course-categories`,
            value: "uuid",
            title: "name",
            perPage: 20
          })
          .isRequired(),
        new SelectField()
          .setKey("isVisible")
          .setItems([
            new SelectItem()
              .setValue(true)
              .setTitle(__("admin.views.courses.form.visible-option")),
            new SelectItem()
              .setValue(false)
              .setTitle(__("admin.views.courses.form.invisible-option"))
          ])
          .setTitle(__("admin.views.courses.form.is-visible")),
      ])
      .setSubmit({ text: __("general.save") })
      .setValidate(true, { onlyRegionLangRequired: true })
      .setOnSuccess(this.onSuccess);
  }

  goBack(): void {
    this.$router.push({ name: AdminRoutes.coursesIndex });
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('admin.views.courses.edit.on-success'),
    })
  }
}
